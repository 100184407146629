import appNadesCardListScreenshot from '../assets/screenshots/en/app-nades-card-list-screenshot.png';
import appFavNades from '../assets/screenshots/en/app-favnades.png';
import appNadesListFilter from '../assets/screenshots/en/app-nades-list-filter.png';
import appNadesOnFav from '../assets/screenshots/en/app-nades-onFav.png';
import appNadesPixelPreview from '../assets/screenshots/en/app-nades-pixel-preview.png';
import appNadesVideoPreview from '../assets/screenshots/en/app-nades-video-preview.png';

import appDemosDetailsFull from '../assets/screenshots/en/app-demos-details-full.png';
import appDemosDetailsRoundPerRound from '../assets/screenshots/en/app-demos-details-roundPerRound.png';
import appDemosFavScreen from '../assets/screenshots/en/app-demos-favScreen.png';
import appDemosList from '../assets/screenshots/en/app-demos-list.png';
import appDemosOnFav from '../assets/screenshots/en/app-demos-onFav.png';

import appNadesCardListScreenshotPt from '../assets/screenshots/pt/app-nades-card-list-screenshot.png';
import appFavNadesPt from '../assets/screenshots/pt/app-favnades.png';
import appNadesListFilterPt from '../assets/screenshots/pt/app-nades-list-filter.png';
import appNadesOnFavPt from '../assets/screenshots/pt/app-nades-onFav.png';
import appNadesPixelPreviewPt from '../assets/screenshots/pt/app-nades-pixel-preview.png';
import appNadesVideoPreviewPt from '../assets/screenshots/pt/app-nades-video-preview.png';

import appDemosDetailsFullPt from '../assets/screenshots/pt/app-demos-details-full.png';
import appDemosDetailsRoundPerRoundPt from '../assets/screenshots/pt/app-demos-details-roundPerRound.png';
import appDemosFavScreenPt from '../assets/screenshots/pt/app-demos-favScreen.png';
import appDemosListPt from '../assets/screenshots/pt/app-demos-list.png';
import appDemosOnFavPt from '../assets/screenshots/pt/app-demos-onFav.png';

import background1 from '../assets/background1.jpg';

import MyCarousel from '../components/MyCarousel';

import { useTranslation } from 'react-i18next';



const grenadesLearningEnImages = [
    {
        image: appNadesCardListScreenshot,
        alt: '',
    },
    {
        image: appNadesListFilter,
        alt: '',
    },
    {
        image: appNadesVideoPreview,
        alt: '',
    },
    {
        image: appNadesPixelPreview,
        alt: '',
    },
    {
        image: appNadesOnFav,
        alt: '',
    },
    {
        image: appFavNades,
        alt: '',
    }
]

const grenadesLearningPtImages = [
    {
        image: appNadesCardListScreenshotPt,
        alt: '',
    },
    {
        image: appNadesListFilterPt,
        alt: '',
    },
    {
        image: appNadesVideoPreviewPt,
        alt: '',
    },
    {
        image: appNadesPixelPreviewPt,
        alt: '',
    },
    {
        image: appNadesOnFavPt,
        alt: '',
    },
    {
        image: appFavNadesPt,
        alt: '',
    }
]



const demoWatchingEnImages = [
    {
        image: appDemosList,
        alt: '',
    },
    {
        image: appDemosDetailsFull,
        alt: '',
    },
    {
        image: appDemosDetailsRoundPerRound,
        alt: '',
    },
    {
        image: appDemosOnFav,
        alt: '',
    },
    {
        image: appDemosFavScreen,
        alt: '',
    },
]

const demoWatchingPtImages = [
    {
        image: appDemosListPt,
        alt: '',
    },
    {
        image: appDemosDetailsFullPt,
        alt: '',
    },
    {
        image: appDemosDetailsRoundPerRoundPt,
        alt: '',
    },
    {
        image: appDemosOnFavPt,
        alt: '',
    },
    {
        image: appDemosFavScreenPt,
        alt: '',
    },
]

function TheApp() {

    const { t, i18n } = useTranslation();

    const grenadesLearningFeatures = [
        {
            name: t("cspro_section_1_feature_1_title"),
            description: t("cspro_section_1_feature_1_p")
        },
        {
            name: t("cspro_section_1_feature_2_title"),
            description: t("cspro_section_1_feature_2_p")
        },
        {
            name: t("cspro_section_1_feature_3_title"),
            description: t("cspro_section_1_feature_3_p")
        },
        {
            name: t("cspro_section_1_feature_4_title"),
            description: t("cspro_section_1_feature_4_p")
        },
    ]

    const demoWatchingFeatures = [
        {
            name: t("cspro_section_2_feature_1_title"),
            description: t("cspro_section_2_feature_1_p")
        },
        {
            name: t("cspro_section_2_feature_2_title"),
            description: t("cspro_section_2_feature_2_p")
        },
        {
            name: t("cspro_section_2_feature_3_title"),
            description: t("cspro_section_2_feature_3_p")
        },
        {
            name: t("cspro_section_2_feature_4_title"),
            description: t("cspro_section_2_feature_4_p")
        },
    ]

    return (
        <div className="bg-white">
            <div className="mx-auto grid max-w-sm grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-6xl lg:grid-cols-2 lg:px-8">

                <MyCarousel images={i18n.language === 'en' ? grenadesLearningEnImages : grenadesLearningPtImages} />

                <div className="ml-36">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t("cspro_section_1_title")}</h2>
                    <p className="mt-4 text-gray-500">
                    {t("cspro_section_1_p")}
                    </p>

                    <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                        {grenadesLearningFeatures.map((feature) => (
                            <div key={feature.name} className="border-t border-gray-200 pt-4">
                                <dt className="font-medium text-gray-900">{feature.name}</dt>
                                <dd className="mt-2 text-sm text-gray-500">{feature.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>

                <div className="mr-36">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t("cspro_section_2_title")}</h2>
                    <p className="mt-4 text-gray-500">
                    {t("cspro_section_2_p")}
                    </p>

                    <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                        {demoWatchingFeatures.map((feature) => (
                            <div key={feature.name} className="border-t border-gray-200 pt-4">
                                <dt className="font-medium text-gray-900">{feature.name}</dt>
                                <dd className="mt-2 text-sm text-gray-500">{feature.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
                <MyCarousel images={i18n.language === 'en' ? demoWatchingEnImages : demoWatchingPtImages} />



            </div>
            {/* Featured section */}
            <section
                aria-labelledby="social-impact-heading"
                className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
            >
                <div className="relative overflow-hidden rounded-lg">
                    <div className="absolute inset-0">
                        <img
                            src={background1}
                            alt=""
                            className="h-full w-full object-cover object-center"
                        />
                    </div>
                    <div className="relative bg-gray-900 bg-opacity-75 px-6 py-32 sm:px-12 sm:py-40 lg:px-16">
                        <div className="relative mx-auto flex max-w-3xl flex-col items-center text-center">
                            <h2 id="social-impact-heading" className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                                <span className="block sm:inline">{t("cspro_section_3_title")}</span>
                            </h2>
                            <p className="mt-3 text-xl text-white">
                                {t("cspro_section_3_p_1")}
                                <br></br>
                                <br></br>
                                {t("cspro_section_3_p_2")}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default TheApp;