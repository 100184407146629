import { Outlet } from "react-router-dom";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import { useState } from "react";
import CookiesBanner from "../components/CookiesBanner";

function RootLayout() {

    const [shouldUpdate, setShouldUpdate] = useState(false);

    const handleShouldUpdate = () => {
        setShouldUpdate(true);

        setTimeout(() => {
            setShouldUpdate(false);
        }, 1000);

        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

        //console.log("should update");
    };

    // Função para obter o valor de um cookie
    function getCookie(name) {
        let value = "; " + document.cookie;
        let parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }
    
    return <>
        <Header onUpdate={shouldUpdate} />
        <main>
            <Outlet />
            
            {!getCookie('cookieConsent') && <CookiesBanner />}
        </main>
        <Footer onClick={handleShouldUpdate}/>
    </>
}

export default RootLayout;