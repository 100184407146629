import PrivacyText from "../components/PrivacyText";
import { useTranslation } from 'react-i18next';


const Privacy = () => {

    const { t } = useTranslation();

    const privacy = [
        {
            "title": t("privacy_title_1"),
            "body": t("privacy_p_1")
        },
        {
            "title": t("privacy_title_2"),
            "body": t("privacy_p_2"),
            "options": [
                t("privacy_p_3"),
                t("privacy_p_4")
            ]
        },
        {
            "title": t("privacy_title_3"),
            "body": t("privacy_p_5"),
            "options": [
                t("privacy_p_6"),
                t("privacy_p_7"),
                t("privacy_p_8")
            ]
        },
        {
            "title": t("privacy_title_4"),
            "body": t("privacy_p_9"),
            "options": [
                t("privacy_p_10"),
                t("privacy_p_11")
            ]
        },
        {
            "title": t("privacy_title_5"),
            "body": t("privacy_p_12")
        },
        {
            "title": t("privacy_title_6"),
            "body": t("privacy_p_13")
        },
        {
            "title": t("privacy_title_7"),
            "body": t("privacy_p_14")
        },
        {
            "title": t("privacy_title_8"),
            "body": t("privacy_p_15")
        }
    ]


    return (
        <div id="privacy" className="relative isolate -z-10 overflow-hidden bg-gradient-to-b from-indigo-100/20">
            <div
                className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96"
                aria-hidden="true"
            />
            <div className="mx-auto max-w-7xl px-6 pt-24 sm:pt-32 pb-4 sm:pb-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
                    <h1 className="max-w-4xl text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl lg:col-span-2 xl:col-auto">
                        {t("privacy_title")}
                    </h1>
                    {privacy.map((privacy, index) => {
                        return <PrivacyText key={index} title={privacy.title} body={privacy.body} options={privacy.options} />;
                    })}
                </div>
            </div>
        </div>
    );
};

export default Privacy;

