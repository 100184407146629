import './App.css';
import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/Home';
import RootLayout from './pages/RootLayout';
import TheApp from './pages/TheApp';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import './i18n/i18n';
import CookiesPolicy from './pages/CookiesPolicy';
import './firebase/config';
import ErrorPage from './pages/ErrorPage';

const router = createBrowserRouter([
  { path: '/', element: <RootLayout />, children: [
      { path: '/', element: <Home /> },
      { path: '/cspro', element: <TheApp /> },
      { path: '/privacy', element: <Privacy /> },
      { path: '/terms', element: <Terms /> },
      { path: '/cookies', element: <CookiesPolicy /> },
    ],
    errorElement: <ErrorPage />
  },
]);

function App() {  

  return (
      <RouterProvider router={router} />
  );
}

export default App;
