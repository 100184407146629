import { Fragment } from "react";

const PrivacyText = (props) => {
    return (
        <Fragment>
            <h2 className="text-xl antialiased font-bold text-slate-950 lg:pt-0 sm:pt-8">{props.title}</h2>
            {props.body && <p className="text-slate-600 lg:pt-0 sm:pt-4">{props.body}</p>}
            {props.options && (
                <ul className="list-disc list-inside lg:pt-0 sm:pt-4">
                    {props.options.map((option, index) => {
                        return <li key={index} className="text-slate-600 lg:pt-0 sm:pt-1">{option}</li>;
                    })}
                </ul>
            )}
            {props.leading && <p className="text-slate-600">{props.leading}</p>}
        </Fragment>
    );
};

export default PrivacyText; 