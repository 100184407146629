import firebase from 'firebase/compat/app';
import { getFunctions } from 'firebase/functions';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAk6ZJ0iVWzdSg_oyDelkb924vhpUgUuGU",
    authDomain: "cspro-ff097.firebaseapp.com",
    projectId: "cspro-ff097",
    storageBucket: "cspro-ff097.appspot.com",
    messagingSenderId: "1018218682913",
    appId: "1:1018218682913:web:e838a204809b0c7d3da160",
    measurementId: "G-DMWB84PWBM"
  };

const app = firebase.initializeApp(firebaseConfig);

const firebaseFunctions = getFunctions(app);

export default firebaseFunctions;