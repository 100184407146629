import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import ReactDOM from 'react-dom';
import CircularProgress from '@mui/joy/CircularProgress';
import { useTranslation } from 'react-i18next';

const portalElement = document.getElementById('overlays');

export default function Modal(props) {

  const roundedColorfulSuccessClass = 'mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100';
  const roundedColorfulErrorClass = 'mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100';
  
  const { t } = useTranslation();

  return <Fragment>
    {ReactDOM.createPortal(

      <Transition.Root show={props.isShown} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={props.onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-slate-950 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  {props.isLoading && <div className='mx-auto flex h-12 w-12 items-center justify-center'>
                    <CircularProgress color="primary" size="md" variant="soft" />
                  </div>}
                  <div>
                    {!props.isLoading && <div className={props.error ? roundedColorfulErrorClass : roundedColorfulSuccessClass}>
                      {!props.error && <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 50 50" >
                        <path d="M 42.875 8.625 C 42.84375 8.632813 42.8125 8.644531 42.78125 8.65625 C 42.519531 8.722656 42.292969 8.890625 42.15625 9.125 L 21.71875 40.8125 L 7.65625 28.125 C 7.410156 27.8125 7 27.675781 6.613281 27.777344 C 6.226563 27.878906 5.941406 28.203125 5.882813 28.597656 C 5.824219 28.992188 6.003906 29.382813 6.34375 29.59375 L 21.25 43.09375 C 21.46875 43.285156 21.761719 43.371094 22.050781 43.328125 C 22.339844 43.285156 22.59375 43.121094 22.75 42.875 L 43.84375 10.1875 C 44.074219 9.859375 44.085938 9.425781 43.875 9.085938 C 43.664063 8.746094 43.269531 8.566406 42.875 8.625 Z"></path>
                      </svg>}
                      {props.error && <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 128 150">
                        <path d="M 64 15.210938 C 59.3 15.210938 55.090234 17.640938 52.740234 21.710938 L 3.2109375 107.5 C 0.8609375 111.57 0.8609375 116.43 3.2109375 120.5 C 5.5609375 124.57 9.7707031 127 14.470703 127 L 113.5293 127 C 118.2293 127 122.43906 124.57 124.78906 120.5 C 127.13906 116.43 127.13906 111.57 124.78906 107.5 L 75.259766 21.710938 C 72.909766 17.640937 68.7 15.210937 64 15.210938 z M 64 21.210938 C 66.53 21.210938 68.800547 22.520937 70.060547 24.710938 L 119.58984 110.5 C 120.85984 112.69 120.85984 115.31 119.58984 117.5 C 118.31984 119.69 116.0593 121 113.5293 121 L 14.470703 121 C 11.940703 121 9.6701563 119.69 8.4101562 117.5 C 7.1401563 115.31 7.1401563 112.69 8.4101562 110.5 L 57.939453 24.710938 C 59.209453 22.520937 61.47 21.210938 64 21.210938 z M 64 51 C 62.34 51 61 52.34 61 54 L 61 84 C 61 85.66 62.34 87 64 87 C 65.66 87 67 85.66 67 84 L 67 54 C 67 52.34 65.66 51 64 51 z M 64 96 C 62.34 96 61 97.34 61 99 L 61 104 C 61 105.66 62.34 107 64 107 C 65.66 107 67 105.66 67 104 L 67 99 C 67 97.34 65.66 96 64 96 z"></path>
                      </svg>}
                    </div>}
                    <div className="mt-3 text-center sm:mt-5">
                      {!props.isLoading && <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-slate-200">
                        {props.error ? t("fail") : props.title}
                      </Dialog.Title>}
                      <div className="mt-2">
                        {!props.isLoading && <p className="text-sm text-slate-300">
                          {props.error ? props.error : props.text}
                        </p>}
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    {!props.isLoading && <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-slate-200 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-slate-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={props.onClose}
                    >
                      {props.buttonText}
                    </button>}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      , portalElement
    )}
  </Fragment>
}
