import { useState } from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function CookiesBanner() {

    const { t } = useTranslation();

    const [shouldShow, setShouldShow] = useState(true);

    const handleCookieCreation = (value) => {
        if(value){
            document.cookie = "cookieConsent=true; max-age=31536000; path=/";
        }else{
            document.cookie = "cookieConsent=; max-age=0; path=/";
            document.cookie = "language=; max-age=0; path=/";
        }
        setShouldShow(false);
    }

    if(shouldShow === false) return <></>;

    return (
      <div className="fixed inset-x-0 bottom-0 flex flex-col justify-between gap-x-8 gap-y-4 bg-slate-950 p-6 ring-1 ring-slate-400/100 md:flex-row md:items-center lg:px-8">
        <p className="max-w-4xl text-sm leading-6 text-slate-100">
            {t("cookies_banner_message")}{' '}
          <Link to="/cookies" className="font-semibold text-yellow-500">
          {t("cookie_policy")}
          </Link>
          .
        </p>
        <div className="flex flex-none items-center gap-x-5">
          <button
            type="button"
            onClick={() => handleCookieCreation(true)}
            className="rounded-md bg-slate-100 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-slate-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
          >
            {t("accept_all")}
          </button>
          <button type="button" onClick={() => handleCookieCreation(false)} className="text-sm font-semibold leading-6 text-slate-100 hover:text-slate-300">
          {t("reject_all")}
          </button>
        </div>
      </div>
    )
  }