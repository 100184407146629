import brazilIcon from '../assets/icons/brazil-icon.png';
import ukIcon from '../assets/icons/uk-icon.png';
import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {

    const { i18n } = useTranslation();

    const activeClass = "w-8 h-8 cursor-pointer";
    const inactiveClass = "w-8 h-8 cursor-pointer opacity-60";

    const handleCookieCreation = (language) => {
        // Definindo um cookie
        document.cookie = `language=${language}; max-age=31536000; path=/`;
    };

    return (
        <div className="isolate flex divide-x divide-gray-200 rounded-lg shadow">
            <button onClick={() => {
                i18n.changeLanguage('en');
                handleCookieCreation('en');
            }} className='px-2 '>
                <img className={i18n.language === 'en' ? activeClass : inactiveClass} src={ukIcon} alt='UK flag' />
            </button>
            <button onClick={() => {
                i18n.changeLanguage('pt');
                handleCookieCreation('pt');
            }} className='px-2'>
                <img className={i18n.language === 'pt' ? activeClass : inactiveClass} src={brazilIcon} alt='Brazilian flag' />
            </button>
        </div>
    );
};

export default LanguageSelector;