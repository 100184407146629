import { Link } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import { useTranslation } from "react-i18next";


const ErrorPage = () => {

    const { t } = useTranslation();

    return (
        <>
            <Header />
            <div className="text-center pt-80 pb-80">
              <p className=" text-5xl font-semibold text-yellow-500">404</p>
              <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">{t("404_title")}</h1>
                <p className="mt-6 text-base leading-7 text-gray-600">{t("404_subtitle")}</p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <Link
                  to="/"
                  className="rounded-md bg-slate-800 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
                >
                  {t("404_button_text")}
                </Link>
              </div>
            </div>
            <Footer />
        </>
    );
};

export default ErrorPage;