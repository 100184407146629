import { Link } from 'react-router-dom';
import appIconDark from '../../assets/logo-dark.png';
import Modal from '../../components/Modal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function Footer(props) {

    const [showNewsletterModal, setShowNewsletterModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [newsletterSubscriptionEmail, setNewsletterSubscriptionEmail] = useState('');

    const { t } = useTranslation();

    const handleNewsletterSubscription = async (e) => {
        e.preventDefault();
    setIsLoading(true);
    setShowNewsletterModal(true);

    try {
      const response = await fetch('https://onsubscribetonewsletter-zhydahdmuq-uc.a.run.app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: newsletterSubscriptionEmail })
      });

      if (!response.ok) {
        return response.json().then((errData) => {
          if (response.status === 401) {
            setIsLoading(false);
            setError(t("error_email_already_exists"));
          }
        });
      } else {
        setShowNewsletterModal(true);

        setError(null);
      }
    }
    catch (err) {
      console.log(err.message || 'Something went wrong!');
      setIsLoading(false);
      setError(err.message ?? 'Something went wrong!');
    }

    setIsLoading(false);
    };

    return (
        <>
            {<Modal isShown={showNewsletterModal} onClose={() => setShowNewsletterModal(false)} isLoading={isLoading} error={error} title={t("footer_modal_title")} text={t("footer_modal_text")} buttonText={t("ok")} />}

            <footer className=" bg-slate-950 mt-10 sm:mt-30" aria-labelledby="footer-heading">
                <h2 id="footer-heading" className="sr-only">Footer</h2>
                <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
                    <div className="grid grid-cols-3 gap-8">
                        <img className="h-7" src={appIconDark} alt="Company name" />
                        <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
                            <div className="md:grid md:grid-cols-2 md:gap-8">
                                <div>
                                    <h3 className="text-sm font-semibold leading-6 text-white">CSPro</h3>
                                    <ul className="mt-6 space-y-4">
                                        <li>
                                            <Link to={'/'} onClick={props.onClick} className="text-sm leading-6 text-gray-300 hover:text-white">{t("footer_nav_theApp")}</Link>
                                        </li>
                                        <li>
                                            <Link to={'/'} onClick={props.onClick} className="text-sm leading-6 text-gray-300 hover:text-white">{t("footer_nav_features")}</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="mt-10 md:mt-0">
                                    <h3 className="text-sm font-semibold leading-6 text-white">{t("footer_nav_title_support")}</h3>
                                    <ul className="mt-6 space-y-4">
                                        <li>
                                            <Link to='/privacy' onClick={props.onClick} className="text-sm leading-6 text-gray-300 hover:text-white">{t("footer_nav_privacy")}</Link>
                                        </li>
                                        <li>
                                            <Link to='/terms' onClick={props.onClick} className="text-sm leading-6 text-gray-300 hover:text-white">{t("footer_nav_terms")}</Link>
                                        </li>
                                        <li>
                                            <Link to='/cookies' onClick={props.onClick} className="text-sm leading-6 text-gray-300 hover:text-white">{t("footer_nav_cookies")}</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="md:grid md:grid-cols-2 md:gap-8">
                                <div>
                                    {/* AQUI VAI O QR CODE DA LOJA */}
                                </div>
                                <div className="mt-10 md:mt-0">

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24 lg:flex lg:items-center lg:justify-between">
                        <div>
                            <h3 className="text-sm font-semibold leading-6 text-white">{t("footer_newsletter_title")}</h3>
                            <p className="mt-2 text-sm leading-6 text-gray-300">{t("footer_newsletter_p")}</p>
                        </div>
                        <form onSubmit={handleNewsletterSubscription} className="mt-6 sm:flex sm:max-w-md lg:mt-0">
                            <label htmlFor="email-address" className="sr-only">Email address</label>
                            <input type="email" name="email-address" id="email-address" autoComplete="email" value={newsletterSubscriptionEmail} onChange={(e) => setNewsletterSubscriptionEmail(e.target.value)} required className="w-full min-w-0 appearance-none rounded-md border-0 bg-white/5 px-3 py-1.5 text-base text-white shadow-sm ring-1 ring-inset ring-white/10 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:w-56 sm:text-sm sm:leading-6" placeholder={t("enter_your_email")} />
                            <div className="mt-4 sm:ml-4 sm:mt-0 sm:flex-shrink-0">
                                <button type="submit" className="flex w-full items-center justify-center rounded-md bg-slate-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-slate-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-400">{t("subscribe")}</button>
                            </div>
                        </form>
                    </div>
                    <div className="mt-8 border-t border-white/10 pt-8 md:flex md:items-center md:justify-between">
                        <div className="flex space-x-6 md:order-2">
                            <a href="https://twitter.com/CSProGG" target='_blank' rel="noreferrer" className="text-gray-500 hover:text-gray-400">
                                <span className="sr-only">Twitter</span>
                                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 48 48" aria-hidden="true">
                                    <path d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z"></path>
                                </svg>
                            </a>
                            <a href="https://www.instagram.com/csprogg" target='_blank' rel="noreferrer" className="text-gray-500 hover:text-gray-400">
                                <span className="sr-only">Instagram</span>
                                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 48 48" aria-hidden="true">
                                    <path d="M 16 3 C 8.83 3 3 8.83 3 16 L 3 34 C 3 41.17 8.83 47 16 47 L 34 47 C 41.17 47 47 41.17 47 34 L 47 16 C 47 8.83 41.17 3 34 3 L 16 3 z M 37 11 C 38.1 11 39 11.9 39 13 C 39 14.1 38.1 15 37 15 C 35.9 15 35 14.1 35 13 C 35 11.9 35.9 11 37 11 z M 25 14 C 31.07 14 36 18.93 36 25 C 36 31.07 31.07 36 25 36 C 18.93 36 14 31.07 14 25 C 14 18.93 18.93 14 25 14 z M 25 16 C 20.04 16 16 20.04 16 25 C 16 29.96 20.04 34 25 34 C 29.96 34 34 29.96 34 25 C 34 20.04 29.96 16 25 16 z"></path>
                                </svg>
                            </a>
                            <a href="https://www.youtube.com/@CSProGG" target='_blank' rel="noreferrer" className="text-gray-500 hover:text-gray-400">
                                <span className="sr-only">YouTube</span>
                                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 58 58" aria-hidden="true">
                                    <path d="M56.456,17.442c-0.339-1.44-1.421-2.595-2.866-3.053C49.761,13.174,41.454,12,32,12s-17.761,1.174-21.591,2.389 c-1.445,0.458-2.527,1.613-2.866,3.053C6.903,20.161,6,25.203,6,32c0,6.797,0.903,11.839,1.544,14.558 c0.339,1.44,1.421,2.595,2.866,3.053C14.239,50.826,22.546,52,32,52s17.761-1.174,21.591-2.389 c1.445-0.458,2.527-1.613,2.866-3.053C57.097,43.839,58,38.797,58,32C58,25.203,57.097,20.161,56.456,17.442z M27,40V24l14.857,8 L27,40z"></path>
                                </svg>
                            </a>
                            <a href="https://www.tiktok.com/@csprogg" target='_blank' rel="noreferrer" className="text-gray-500 hover:text-gray-400">
                                <span className="sr-only">Tiktok</span>
                                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 48 48" aria-hidden="true">
                                    <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z"></path>
                                </svg>
                            </a>
                        </div>
                        <p className="mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0">{t("footer_rights")}</p>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;