import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enJson from './locales/en.json';
import ptJson from './locales/ptBR.json';

i18n.use(initReactI18next).init({
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    }, 
    resources: {
        en: {
            translation: enJson,
        },
        pt: {
            translation: ptJson,
        },
    },
});


// Obtendo um cookie
let getCookie = (name) => {
    let value = "; " + document.cookie;
    let parts = value.split("; " + name + "=");
    if (parts.length === 2) return parts.pop().split(";").shift();
}

let userLanguage = getCookie('language');

if(userLanguage){
    i18n.changeLanguage(userLanguage);
}

export default i18n;