import { Dialog } from '@headlessui/react'
import { useEffect, useState } from "react";
import logo from '../../assets/logo-dark.png';
import { Link } from 'react-router-dom';
import LanguageSelector from '../LanguageSelector';
import { useTranslation } from 'react-i18next';

const Header = (props) => {

    const { t } = useTranslation();

    const [shouldUpdate, setShouldUpdate] = useState(false);

    const navigation = [
        { name: t("home"), href: '/', index: 0 },
        { name: t("cspro"), href: '/cspro', index: 1 },
        { name: t("privacy_policy"), href: '/privacy', index: 2 },
        { name: t("terms_of_use"), href: '/terms', index: 3 },
    ]

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const [activeNavIndex, setActiveNavIndex] = useState(0);

    const activeClass = "text-base font-semibold leading-6 text-yellow-500 hover:text-yellow-400";
    const inactiveClass = "text-base font-semibold leading-6 text-slate-100 hover:text-slate-300";

    useEffect(() => {
        if(window.location.pathname === "/")
        { setActiveNavIndex(0);
            return;
        }
        if(window.location.pathname === "/cspro"){
            setActiveNavIndex(1);
            return;
        }
        if(window.location.pathname === "/privacy"){
            setActiveNavIndex(3);
            return;
        }
        if(window.location.pathname === "/terms"){
            setActiveNavIndex(3);
            return;
        }

        setActiveNavIndex(-1);


    }, [shouldUpdate]);


    useEffect(() => {
        setShouldUpdate(props.onUpdate);
    }, [props.onUpdate]);

    return (
        <header className="absolute inset-x-0 top-0 z-50 bg-slate-950">
            <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                <div className="flex lg:flex-1">
                    <Link to="#" className="-m-1.5 p-1.5">
                        <span className="sr-only">Your Company</span>
                        <img
                            className="h-8 w-auto"
                            src={logo}
                            alt=""
                        />
                    </Link>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <svg fill="none" viewBox="0 0 15 15" height="1.5em" width="1.5em">
                            <path
                                fill="currentColor"
                                fillRule="evenodd"
                                d="M1.5 3a.5.5 0 000 1h12a.5.5 0 000-1h-12zM1 7.5a.5.5 0 01.5-.5h12a.5.5 0 010 1h-12a.5.5 0 01-.5-.5zm0 4a.5.5 0 01.5-.5h12a.5.5 0 010 1h-12a.5.5 0 01-.5-.5z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                </div>
                <div className="hidden lg:flex lg:gap-x-12">
                    {navigation.map((item) => (
                        <Link onClick={() => setActiveNavIndex(item.index)} key={item.name} to={item.href} className={activeNavIndex === item.index ? activeClass : inactiveClass}>
                            {item.name}
                        </Link>
                    ))}
                </div>
                <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                    <LanguageSelector />
                    {/* <a href="#" className="text-sm font-semibold leading-6 text-white">
                        Log in <span aria-hidden="true">&rarr;</span>
                    </a> */}
                </div>
            </nav>
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-50" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-slate-950 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <Link to="#" className="-m-1.5 p-1.5">
                            <span className="sr-only">Your Company</span>
                            <img
                                className="h-8 w-auto"
                                src={logo}
                                alt=""
                            />
                        </Link>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-white"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <svg fill="none" viewBox="0 0 24 24" height="1.5em" width="1.5em">
                                <path
                                    fill="currentColor"
                                    d="M6.225 4.811a1 1 0 00-1.414 1.414L10.586 12 4.81 17.775a1 1 0 101.414 1.414L12 13.414l5.775 5.775a1 1 0 001.414-1.414L13.414 12l5.775-5.775a1 1 0 00-1.414-1.414L12 10.586 6.225 4.81z"
                                />
                            </svg>
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                {navigation.map((item) => (
                                    <Link
                                        key={item.name}
                                        to={item.href}
                                        onClick={() => {
                                            setMobileMenuOpen(false);
                                            setActiveNavIndex(item.index);
                                        }}
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-slate-100 hover:bg-slate-700"
                                    >
                                        {item.name}
                                    </Link>
                                ))}
                            </div>
                            <LanguageSelector />
                            {/* <div className="py-6">
                                <a
                                    href="#"
                                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-50"
                                >
                                    Log in
                                </a>
                            </div>  */}
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    );
};

export default Header;