import React from "react";
import { useTranslation } from 'react-i18next';
import PrivacyText from "../components/PrivacyText";

const CookiesPolicy = () => {

    const { t } = useTranslation();

    const cookies = [
        {
            "title": t("cookies_title_1"),
            "body": t("cookies_p_1")
        },
        {
            "title": t("cookies_title_2"),
            "options": [
                t("cookies_p_2"),
                t("cookies_p_3"),
            ]
        },
        {
            "title": t("cookies_title_3"),
            "options": [
                t("cookies_p_4"),
            ]
        },
        {
            "title": t("cookies_title_4"),
            "options": [
                t("cookies_p_5"),
            ]
        },
        {
            "title": t("cookies_title_5"),
            "body": t("cookies_p_6")
        },
        {
            "title": t("cookies_title_6"),
            "body": t("cookies_p_7")
        },
    ]

    return (
        <div id="cookies" className="relative isolate -z-10 overflow-hidden bg-gradient-to-b from-indigo-100/20">
            <div
                className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96"
                aria-hidden="true"
            />
            <div className="mx-auto max-w-7xl px-6 pt-24 sm:pt-32 pb-4 sm:pb-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
                    <h1 className="max-w-4xl text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl lg:col-span-2 xl:col-auto">
                        {t("cookies_title")}
                    </h1>
                    {cookies.map((cookies, index) => {
                        return <PrivacyText key={index} title={cookies.title} body={cookies.body} options={cookies.options} />;
                    })}
                </div>
            </div>
        </div>
    );
};

export default CookiesPolicy;