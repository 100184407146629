import csproFeaturesImageEN from '../assets/csproFeaturesImageEN.png';
import csproFeaturesImagePT from '../assets/csproFeaturesImagePT.png';

import appNadesScreenshotEn from '../assets/screenshots/en/app-nades-card-list-screenshot.png';
import appNadesScreenshotPt from '../assets/screenshots/pt/app-nades-card-list-screenshot.png';

import grenadeIcon2 from '../assets/icons/grenade-icon.png';
import watchDemoIcon from '../assets/icons/watch-demo-icon.png';
import newFeaturesIcon from '../assets/icons/incoming-features-icon.png';

import appleIcon from '../assets/icons/apple-logo.png';
import androidIcon from '../assets/icons/android-logo.png';

import { useState } from 'react';
import Modal from '../components/Modal';
import { useTranslation } from 'react-i18next';

const HomePage = () => {

  const [preAccessEmail, setPreAccessEmail] = useState('');
  const [newsletterSubscriptionEmail, setNewsletterSubscriptionEmail] = useState('');

  const [showPreAccessModal, setShowPreAccessModal] = useState(false);
  const [showNewsletterModal, setShowNewsletterModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const { t, i18n } = useTranslation();

  const handlePreAccessSubscription = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setShowPreAccessModal(true);

    try {
      const response = await fetch('https://onrequestpreaccess-zhydahdmuq-uc.a.run.app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: preAccessEmail })
      });

      if (!response.ok) {
        return response.json().then((errData) => {
          if (response.status === 401) {
            setIsLoading(false);
            setError(t("error_email_already_exists"));
          }
        });
      } else {
        setShowPreAccessModal(true);
        setError(null);
      }
    }
    catch (err) {
      console.log(err.message || 'Something went wrong!');
      setIsLoading(false);
      setError(err.message ?? 'Something went wrong!');
    }

    setIsLoading(false);
  };

  const handleNewsletterSubscription = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setShowNewsletterModal(true);

    try {
      const response = await fetch('https://onsubscribetonewsletter-zhydahdmuq-uc.a.run.app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: newsletterSubscriptionEmail })
      });

      if (!response.ok) {
        return response.json().then((errData) => {
          if (response.status === 401) {
            setIsLoading(false);
            setError(t("error_email_already_exists"));
          }
        });
      } else {
        setShowNewsletterModal(true);

        setError(null);
      }
    }
    catch (err) {
      console.log(err.message || 'Something went wrong!');
      setIsLoading(false);
      setError(err.message ?? 'Something went wrong!');
    }

    setIsLoading(false);

  };

  return (

    <>
      {<Modal isShown={showPreAccessModal} onClose={() => setShowPreAccessModal(false)} isLoading={isLoading} error={error} title={t("success")} text={t("preAccess_modal_text")} buttonText={t("ok")} />}
      {<Modal isShown={showNewsletterModal} onClose={() => setShowNewsletterModal(false)} isLoading={isLoading} error={error} title={t("footer_modal_title")} text={t("footer_modal_text")} buttonText={t("ok")} />}

      <div className="relative isolate bg-gray-100">
        <div id='presentation' className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
            <h1 className="mt-10 max-w-2xl text-4x1 font-bold tracking-tight text-gray-900 sm:text-6xl">
              {t("presentation_title")}
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              {t("presentation_first_p")}
            </p>
            <div className="relative isolate overflow-hidden px-6 py-24 sm:rounded-3xl sm:px-24 xl:py-32 ">
              <h2 className="mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight text-slate-800 sm:text-4xl">{t("presentation_subtitle")}</h2>
              {/* <p className="mx-auto mt-2 max-w-xl text-center text-lg leading-8 text-slate-500">{t("presentation_second_p")}</p> */}
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <a
                  href='https://apps.apple.com/us/app/cspro-cs2-guide/id6466211996' target='_blank' rel='noreferrer'
                  className="inline-flex items-center gap-x-1.5 rounded-md bg-slate-800 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
                >
                  <img src={appleIcon} alt='Apple Icon'/>
                  {t("get_access")}
                </a>
                <a
                  href='https://play.google.com/store/apps/details?id=gg.cspro' target='_blank' rel='noreferrer'
                  className="inline-flex items-center gap-x-1.5 rounded-md bg-green-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-400"
                >
                  <img src={androidIcon} alt='Android Icon'/>
                  {t("get_access")}
                </a>
              </div>
              {/* <form onSubmit={handlePreAccessSubscription} className="mx-auto mt-10 flex max-w-md gap-x-4">
                <label htmlFor="email-address" className="sr-only">Email address</label>
                <input id="email-address" name="email" type="email" autoComplete="email" value={preAccessEmail} onChange={(e) => setPreAccessEEmail(e.target.value)} required className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-black shadow-sm ring-1 ring-inset ring-gray-950 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6" placeholder={t("enter_your_email")} />
                <button type="submit" className="flex-none rounded-md bg-slate-800 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">{t("get_access")}</button>
              </form> */}
            </div>
          </div>
          <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
            <svg viewBox="0 0 366 729" role="img" className="mx-auto w-[30rem] max-w-full drop-shadow-xl">
              <title>App screenshot</title>
              <defs>
                <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                  <rect width={316} height={684} rx={36} />
                </clipPath>
              </defs>
              <path
                fill="#4B5563"
                d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
              />
              <path
                fill="#343E4E"
                d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
              />
              <foreignObject
                width={316}
                height={684}
                transform="translate(24 24)"
                clipPath="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
              >
                <img src={i18n.language === 'en' ? appNadesScreenshotEn : appNadesScreenshotPt} alt="" />
              </foreignObject>
            </svg>
          </div>
        </div>
      </div>

      <div className="relative isolate bg-white">
        <div id='features' className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
            <h1 className="mt-10 max-w-2xl text-4x1 font-bold tracking-tight text-gray-900 sm:text-6xl">
              {t("features_title")}
            </h1>
            <div className="max-w-6xl lg:row-start-3 lg:mt-10 lg:max-w-md lg:border-t lg:border-white/10 lg:pt-10">
              <dl className="max-w-xl space-y-8 text-base leading-7 text-gray-300 lg:max-w-none">
                <div className="relative">
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                    <img src={grenadeIcon2} alt="" />
                    {t("features_feature_1_title")}
                  </dt>
                  <dd className="inline text-slate-500">{t("features_feature_1_p")}</dd>
                </div>
                <div className="relative">
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                    <img src={watchDemoIcon} alt="" height={20} width={20} />
                    {t("features_feature_2_title")}
                  </dt>
                  <dd className="inline text-slate-500">{t("features_feature_2_p")}</dd>
                </div>
                <div className="relative">
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                    <img src={newFeaturesIcon} alt="" height={20} width={20} />
                    {t("features_feature_3_title")}
                  </dt>
                  <dd className="inline text-slate-500">{t("features_feature_3_p")}</dd>
                </div>
              </dl>
            </div>

          </div>
          <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
            <svg viewBox="0 0 366 729" role="img" className="mx-auto w-[30rem] max-w-full drop-shadow-xl">
              <title>App screenshot</title>
              <defs>
                <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                  <rect width={316} height={684} rx={36} />
                </clipPath>
              </defs>
              <path
                fill="#4B5563"
                d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
              />
              <path
                fill="#343E4E"
                d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
              />
              <foreignObject
                width={316}
                height={684}
                transform="translate(24 24)"
                clipPath="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
              >
                <img src={i18n.language === 'en' ? csproFeaturesImageEN : csproFeaturesImagePT} alt="" />
              </foreignObject>
            </svg>
          </div>
        </div>
      </div>

      <div className="mx-auto mb-36 max-w-7xl sm:px-6 lg:px-8">
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 shadow-2xl sm:rounded-3xl sm:px-24 xl:py-32">
          <h2 className="mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight text-white sm:text-4xl">
            {t("subscribe_to_newsletter_p")}
          </h2>
          <form onSubmit={handleNewsletterSubscription} className="mx-auto mt-10 flex max-w-md gap-x-4">
            <label htmlFor="email-address" className="sr-only">Email address</label>
            <input id="email-address" name="email" type="email" autoComplete="email" value={newsletterSubscriptionEmail} onChange={(e) => setNewsletterSubscriptionEmail(e.target.value)} required className="min-w-0 flex-auto rounded-md border-0 bg-white/20 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-slate-200 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6" placeholder={t("enter_your_email")} />
            <button type="submit" className="flex-none rounded-md bg-slate-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-slate-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-400">{t("subscribe")}</button>
          </form>
          <svg
            viewBox="0 0 1024 1024"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2"
            aria-hidden="true"
          >
            <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
            <defs>
              <radialGradient
                id="759c1415-0410-454c-8f7c-9a820de03641"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(512 512) rotate(90) scale(512)"
              >
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#002f4f" stopOpacity={0} />
              </radialGradient>
            </defs>
          </svg>
        </div>
      </div>

    </>
  )
};

export default HomePage;